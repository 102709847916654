import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

/*
const IndexPage = () => (
  <Layout>
    <SEO title="Efficient Image Annotation" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image src='gatsby-astronaut.png' />
    </div>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)
*/

const IndexPage = () => (
  <Layout>
    <SEO title="Accurate Image Annotation"/>
    <section id="intro" className="panes">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            {/*// <!-- intro text -->*/}
            <div className="text">
              {/*// <!--<h1>Impactful Image Annotation</h1>-->*/}
              {/*// <!--<span>Support our ecosystem of education and digital work for Nigerian youth and get accurate training data for AI Applications in return.</span>-->*/}
              <h1>Accurate Image Annotation</h1>
              <span>More of your money is spent on Quality Control because of our unique Non-Profit mission.</span>

              {/*// <!-- try Humainly -->*/}
              <div className="push-b">
                <Link to='/contact/' className="button button--red button--rounded button--large">Get Started for free</Link>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="hero">
              <img src={'/images/hero.svg'} alt=""/>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/*// <!-- media -->*/}
    <section className="media text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <img src={'/images/support/nvidia.png'} className="media-logo" alt="Nvidia Inception"/>
          </div>
          <div className="col-md-3">
            <img src={'/images/support/startups.png'} className="media-logo" alt="Startups.be"/>
          </div>
          <div className="col-md-3">
            <img src={'/images/support/startit.jpg'} className="media-logo" alt="Start It"/>
          </div>
          <div className="col-md-3">
            <img src={'/images/support/birdhouse.png'} className="media-logo" alt="The Birdhouse"/>
          </div>
        </div>
      </div>
    </section>

    {/*// <!-- overview -->*/}
    <section className="overview">
      <div className="container">
        <div className="center b-padding l-padding r-padding">
          <h1 className="c-dark">Why you should choose us</h1>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="box-item">
              <img src={'/images/why/start.svg'} alt=""/>
              <h3 className="t-padding-sm">Impactful</h3>
              <span>You help us create work that keeps unemployed Nigerians off the streets and into training programs.</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="box-item">
              <img src={'/images/why/pipeline.svg'} alt=""/>
              <h3 className="t-padding-sm">Accurate</h3>
              <span>As a non-profit we’ve partnered with multi-million dollar software solutions to guarantee you the highest quality possible.</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="box-item">
              <img src={'/images/why/collaboration.svg'} alt=""/>
              <h3 className="t-padding-sm">Reliable</h3>
              <span>The only way to reliably help both you and Nigerian youngsters, is by making sure we solve your needs better than our competition does.</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/*// <!-- approach -->*/}
    <section className="pt-5 pb-4 b-gray">
      <div className="container">
        <div className="center b-padding l-padding r-padding">
          <h1 className="c-dark">Our Approach</h1>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="box-item">
              <h3 className="t-padding-sm">Quality</h3>
              <p className="text-justify">Delivering quality is about
                adding redundancy while maximizing profit is
                about reducing it. As a non-profit, we can therefore offer you the best quality that your money can buy.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="box-item">
              <h3 className="t-padding-sm">Impact</h3>
              <p>Working with us creates impact in two ways:</p>
              <ol className="pt-3 text-left">
                <li>It provides local NGO’s with the means to offer training for free.</li>
                <li className="pt-3">It provides fair income to trainees so they don’t have to live on the street.</li>
              </ol>
            </div>
          </div>
          <div className="col-md-4">
            <div className="box-item">
              <h3 className="t-padding-sm">Pricing</h3>
              <p className="text-justify">Our most flexible offer allows you to pay as you go, without up-front
                commitments, for as little as
                7&euro; per hour.</p>
              <p className="text-justify">Custom pricing is possible after testing our service for free.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="pt-5 pb-2">
      <div className="container">
        <div className="text-center footer-top">
          <h2>Take the next step and Try for free</h2>
          <div className="push-b">
            <ul className="list-inline">
              <li className="list-inline-item">
                <Link to='/contact/' className="button button--red button--rounded button--large">Get Started for free</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
